#invoice-POS {
    /* box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); */
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 2mm;
    margin: 0 auto;
    width: 66mm;
    background: #FFF;

    ::selection {background: #f31544; color: #FFF;}
    ::moz-selection {background: #f31544; color: #FFF;}
}

#invoice-POS h1{
    font-size: 1.5em !important;
        color: #222;
}
#invoice-POS h2{
    font-size: .9em !important;
}
#invoice-POS h3 {
    font-size: 1.2em !important;
    font-weight: 300;
    line-height: 2em;
}
#invoice-POS p {
    font-size: .7em !important;
    color: #666;
    line-height: 1.2em;
}
#invoice-POS .logo {
    /* float: left; */
    height: 60px;
    width: 60px;
    background: url(https://back-pos.hostprohub.com//frontend/images/site_logo/KANFAST_Black.svg) no-repeat;
    background-size: 60px 60px;
}


#invoice-POS #top,
#mid,
#bot {
    /* Targets all id with 'col-' */
    border-bottom: 1px solid #EEE;
}

#invoice-POS #top {
    min-height: 100px;
}

#invoice-POS #mid {
    min-height: 80px;
}

#invoice-POS #bot {
    min-height: 50px;
}

#invoice-POS #top .logo {
    /* float: left; */
    height: 60px;
    width: 60px;
    background: url(https://back-pos.hostprohub.com//frontend/images/site_logo/KANFAST_Black.svg) no-repeat;
    background-size: 60px 60px;
}

#invoice-POS .clientlogo {
    float: left;
    height: 60px;
    width: 60px;
    background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
    background-size: 60px 60px;
    border-radius: 50px;
}

#invoice-POS .info {
    display: block;
    /* float:left; */
    margin-left: 0;
}

#invoice-POS .title {
    float: right;
}

#invoice-POS .title p {
    text-align: right;
}

#invoice-POS table {
    width: 100%;
    border-collapse: collapse;
}

#invoice-POS td {
    /* padding: 5px 0 5px 15px; */
    /* border: 1px solid #EEE */
}

#invoice-POS .tabletitle {
    /* padding: 5px; */
    font-size: .5em;
    background: #EEE;
}

#invoice-POS .service {
    border-bottom: 1px solid #EEE;
}

#invoice-POS .item {
    width: 24mm;
}

#invoice-POS .itemtext {
    font-size: .5em;
}

#invoice-POS #legalcopy {
    margin-top: 5mm;
}

@media print {
    .print-invoice-sec {
      width: 66mm; /* Set the specific width for printing */
      margin: 0 auto; /* Center the content on the printed page */
    }
  
    /* Add any other print-specific styles as needed */
  }